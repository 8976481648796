import React from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Homescreen from './pages/Homescreen';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to="/starSportsSD" />} />
      <Route path='/:channel_id' element={<Homescreen />} />
      <Route path='/:channel_id/:ad_id' element={<Homescreen />} />
    </Routes>
  );
}

export default App;
