import React, { useState, useEffect } from 'react';

function Tooltip({ tooltipRef }) {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            tooltipRef.current.classList.add('hidden');
        }, 10 * 1000); 

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className={`tool-tip ${visible ? 'visible' : 'hidden'}`} ref={tooltipRef}>
            <div className='content'>Select channel from here!</div>
            <div className="arrow"></div>
        </div>
    );
}

export default Tooltip;
