import React, { useEffect, useRef, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'
import platform from 'platform';
import ChannelMenu from '../components/ChannelMenu';
import Form from '../components/Form';
import Tooltip from '../components/Tooltip';

const Homescreen = () => {
    let data = {
        starSportsSD: [
            {
                ad: 1,
                logo: "/assets/images/mahindra-brand-icon.svg",
                adImage: "/assets/images/mahindra-ad.svg",
                url: "",
                btnType: "black-btn",
                btnAction: "formSubmission",
                btnArrow: "/assets/images/btn-arrow-white.svg"
            },
            {
                ad: 2,
                logo: "/assets/images/audi-brand-icon.svg",
                adImage: "/assets/images/audi-ad.svg",
                url: "",
                btnType: "white-btn",
                btnAction: "redirect",
                btnArrow: "/assets/images/btn-arrow-black.svg"
            },
            {
                ad: 3,
                logo: "/assets/images/ditto-brand-icon.svg",
                adImage: "/assets/images/ditto-ad.svg",
                url: "",
                btnType: "black-btn",
                btnAction: "redirect",
                btnArrow: "/assets/images/btn-arrow-white.svg"
            }
        ],
        starSportsHD: [
            {
                ad: 1,
                logo: "/assets/images/mahindra-brand-icon.svg",
                adImage: "/assets/images/mahindra-ad.svg",
                url: "",
                btnType: "black-btn",
                btnAction: "formSubmission",
                btnArrow: "/assets/images/btn-arrow-white.svg"
            },
            {
                ad: 2,
                logo: "/assets/images/audi-brand-icon.svg",
                adImage: "/assets/images/audi-ad.svg",
                url: "",
                btnType: "white-btn",
                btnAction: "redirect",
                btnArrow: "/assets/images/btn-arrow-black.svg"
            },
            {
                ad: 3,
                logo: "/assets/images/ditto-brand-icon.svg",
                adImage: "/assets/images/ditto-ad.svg",
                url: "",
                btnType: "black-btn",
                btnAction: "redirect",
                btnArrow: "/assets/images/btn-arrow-white.svg"
            }
        ]
    }

    const navigate = useNavigate();
    const { channel_id, ad_id } = useParams();

    const [showMenu, setShowMenu] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const [channel, setChannel] = useState(channel_id);
    const [ad, setAd] = useState(null);
    const [adInfo, setAdInfo] = useState(null);
    const [location, setLocation] = useState(null);
    const [browser, setBrowser] = useState(null);

    const handleShowMenu = () => {
        setShowMenu(true);
        document.body.classList.add('no-scroll');
    }
    
    const handleHideMenu = () => {
        document.body.classList.remove('no-scroll');
        setShowMenu(false);
    }

    const handleShowForm = (value) => {
        setShowForm(value);
        if (value === true) {
            document.body.classList.add('no-scroll');
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        } else {
            document.body.classList.remove('no-scroll');
        }
    }

    const handleChannelChange = (channel) => {
        setChannel(channel);
        navigate(`/${channel}`);
        handleHideMenu();
    }

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    fetchIPLocation();
                }
            );
        } else {
            console.error('Geolocation is not available in this browser.');
        }

        setBrowser(platform.name);

    }, []);

    const fetchIPLocation = async () => {
        const response = await fetch("https://ipapi.co/json/");
        const location = await response.json();
        setLocation({
            latitude: location.latitude,
            longitude: location.longitude
        })
    }

    const handleBannerBtn = (action) => {
        if (action === 'redirect') {
            console.log("redirect to external link");
        } else if (action === 'formSubmission') {
            handleShowForm(true);
        }
    }

    const tooltipRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                tooltipRef.current.classList.add('hidden');
                tooltipRef.current.classList.remove('visible');
            }
        }

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);

    const fetchAd = async (channelID) => {
        let language = navigator.language;

        const formatDateToISO = (date) => {
            return date.toISOString();
        };

        const currentDate = new Date();

        const currentISODateTime = formatDateToISO(currentDate);

        let bodyContent = {
            tv_scan: {
                tv_name: channelID,
                time: currentISODateTime,
                latitude: location?.latitude,
                longitude: location?.longitude,
                metadata: {
                    browser: browser,
                    language: language
                }
            }
        }

        const response = await fetch('https://portal-api.brandactif.com/api/v2/tv_scans/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'api-key': 'd27aea927c90398e7566a71051e0cb064fdea86352fb6a017edbeee0ddbf1350'
            },
            body: JSON.stringify(bodyContent)
        });

        const currentAd = await response.json();

        if (Object.keys(currentAd["response_details"]).length > 0) {

            let adID = await currentAd["response_details"]["ad"];

            localStorage.setItem(channelID,adID);

            navigate(`/${channelID}/${adID}`);
        } else {
            let ad = localStorage.getItem(channelID);

            navigate(`/${channelID}/${ad ? ad : 1}`);
        }

    }

    useEffect(() => {
        setChannel(channel_id);

        if (ad_id) {
            setAd(ad_id);
        } else {
            fetchAd(channel_id);
        }

        if (channel_id && ad_id) {
            let channelInfo = data[channel_id];
            let adObj = channelInfo?.find((item) => item.ad === parseInt(ad_id));
            setAdInfo(adObj);
        }

    }, [channel_id, ad_id]);

    useEffect(() => {
        const intervalID = setInterval(() => {
            fetchAd(channel);
        }, 60 * 1000);

        return () => clearInterval(intervalID);

    }, [channel])


    return (
        <div className='homepage'>
            <div className='container'>
                <div className='header'>
                    {adInfo ? <div className='active-ad-log'>
                        <img src={adInfo?.logo} width={58} height={51} alt="Ad Brand Icon" />
                    </div> :
                        <SkeletonTheme baseColor='#f4f4f4' highlightColor='#fff'>
                            <Skeleton circle={true} width={58} height={58} containerClassName='logo-skeleton' />
                        </SkeletonTheme>
                    }
                    <div className='channel-info' onClick={handleShowMenu}>
                        <div className='channel-image'>
                            <img src="/assets/images/star-sports-1.svg" width={51} height={62} alt="Star Sports 1 Icon"/>
                        </div>
                        <div className='remote-image'>
                            <img src='/assets/images/remote-icon.svg' width={24} height={24} alt="Star Sports 2 Icon" />
                        </div>
                    </div>
                    <Tooltip tooltipRef={tooltipRef} />
                </div>
                <div className='ad-section'>
                    {adInfo ? <div className='ad-container'>
                        <img src={adInfo?.adImage} className='ad-image' alt="Ad Commercial" />
                        <button className={`${adInfo?.btnType}`} onClick={() => handleBannerBtn(adInfo?.btnAction)}>
                            Explore Now
                            <img src={`${adInfo?.btnArrow}`} className='explore-btn-arrow' alt="Arrow Icon" />
                        </button>
                    </div> :
                        <SkeletonTheme baseColor='#f4f4f4' highlightColor='#fff'>
                            <Skeleton height="100%" containerClassName='banner-skeleton' />
                        </SkeletonTheme>
                    }
                </div>
                <div className='ad-list'>
                    {(data[channel] && adInfo) ?
                        <>
                            {data[channel].map((brand, index) => (
                                <div className={`ad ${brand.ad === parseInt(ad) ? "active" : ""}`} key={index}>
                                    <img src={brand?.logo} width={58} height={51} alt="Brand Logo" />
                                </div>
                            ))}
                        </>
                        :
                        <SkeletonTheme baseColor='#f4f4f4' highlightColor='#fff'>
                            <Skeleton circle={true} inline width={58} height={58} containerClassName='logo-skeleton' />
                            <Skeleton circle={true} inline width={58} height={58} containerClassName='logo-skeleton' />
                            <Skeleton circle={true} inline width={58} height={58} containerClassName='logo-skeleton' />
                        </SkeletonTheme>
                    }
                </div>
            </div>
            {showMenu && <ChannelMenu handleHideMenu={handleHideMenu} handleChannelChange={handleChannelChange} channel={channel} />}
            {showForm && <Form handleShowForm={handleShowForm} />}
        </div>
    )
}

export default Homescreen;