import React from "react";

const ChannelMenu = ({ handleHideMenu, handleChannelChange, channel }) => {
    return (
        <div className='menu-section' onClick={handleHideMenu}>
            <div className='channels-list-card' >
                <div className='card-header'>
                    <img src='/assets/images/remote-icon.svg' width={24} height={24} alt="Remote Icon" />
                    <p className='channel-heading'>Pick a channel</p>
                </div>
                <div className='card-body'>
                    <ul className='channel-list'>
                        <li className={`channel ${channel === "starSportsSD" ? "active-channel" : ""}`} onClick={() => handleChannelChange("starSportsSD")}>
                            <img src='/assets/images/star-sports-1.svg' width={62} height={51} alt="Star Sports 1 Icon" />
                            <p className='channel-name'>Star Sports SD</p>
                        </li>
                        <li className={`channel ${channel === "starSportsHD" ? "active-channel" : ""}`} onClick={() => handleChannelChange("starSportsHD")}>
                            <img src='/assets/images/star-sports-1.svg' width={62} height={51} alt="Star Sports 2 Icon" />
                            <p className='channel-name'>Star Sports HD</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ChannelMenu;