import React, { useState, useEffect } from "react";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

const Form = ({ handleShowForm }) => {

    const [formValue, setFormValue] = useState({
        name: '',
        email: '',
        phone: ''
    })

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'phone') {
            const sanitizedValue = value.replace(/[^0-9]/g, '');

            const maxLength = 15;
            const truncatedValue = sanitizedValue.slice(0, maxLength);

            setFormValue({
                ...formValue,
                [name]: truncatedValue
            });
        } else {
            setFormValue({
                ...formValue,
                [name]: value
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formValue);
        alert('Form Submitted Successfully');
        handleShowForm(false);
    }

    useEffect(() => {
        const phoneInputField = document.querySelector('#phone');
        const phoneInputInstance = intlTelInput(phoneInputField, {
            initialCountry: 'auto',
            geoIpLookup: (callback) => {
                fetch('https://ipapi.co/json')
                    .then((res) => res.json())
                    .then((data) => callback(data.country_code))
                    .catch(() => callback('us'));
            },
            separateDialCode: true,
            showFlags: false,
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
        });

        return () => {
            phoneInputInstance.destroy();
        };
    }, []);

    return (
        <div className='menu-section form-section'>
            <div className='close-menu' onClick={() => handleShowForm(false)}>
                <img src='/assets/images/cross-icon.svg' width={15} height={15} alt="Cross Icon" />
            </div>
            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-header">
                    <h3 className="heading">Get in touch</h3>
                    <img src="/assets/images/mahindra-brand-icon.svg" width={45} height={45} alt="Mahindra Brand Icon"/>
                </div>
                <div className="name-input">
                    <input type="text" placeholder="Name" name="name" className="form-input" required value={formValue.name} onChange={handleChange} />
                </div>
                <div className="email-input">
                    <input type="email" placeholder="Email" name="email" className="form-input" value={formValue.email} onChange={handleChange} />
                </div>
                <div className="phone-input">
                    <input type="tel" id="phone" placeholder="Phone Number" name="phone" className="form-input" required value={formValue.phone} onChange={handleChange} />
                </div>
                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </div>
    )
}

export default Form;